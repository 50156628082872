import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AuthProvider } from 'oidc-react';

const oidcConfig = {
    onSignIn: async (user: any) => {
        window.location.hash = '';
        console.log('OOZER', user && JSON.stringify(user))
    },
    authority: 'https://auth.binaried.me',
    clientId: '6ebb6adf-dd2a-4f06-8f8f-de2c5e6df49f',
    redirectUri: 'https://binaried.me/oauth-callback',
    responseType: 'code',
    scope: 'openid profile email',
    automaticSilentRenew: true,
    silentRedirectUri: 'https://binaried.me/silent-renew.html',
};

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
      <AuthProvider {...oidcConfig}>
          <App />
      </AuthProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
