import React from 'react';
import { useAuth } from 'oidc-react';
import './App.css';

function App() {

    const auth = useAuth();

    const login = () => {
        auth.signIn();
    };

    const logout = () => {
        auth.signOut();
    };

    if (auth.userData) {
        return (
            <div>
                <h1>Welcome, {auth.userData.profile.name || auth.userData.profile.email}!</h1>
                <button onClick={logout}>Logout</button>
                <pre>{JSON.stringify(auth.userData.profile, null, 2)}</pre>
            </div>
        );
    } else {
        return (
            <div>
                <h1>You are not logged in.</h1>
                <button onClick={login}>Login</button>
            </div>
        );
    }

    /*return (
      <div className="App">
        <a href="https://auth.binaried.me/oauth2/authorize?client_id=6ebb6adf-dd2a-4f06-8f8f-de2c5e6df49f&response_type=code&redirect_uri=https%3A%2F%2Fbinaried.me%2Fprofile&scope=openid%20profile%20email">
          <button>Login with Google</button>
        </a>

      </div>
  );*/

}

export default App;
